import "styles/pages/privacy-policy.scss"

import React, { useState } from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import {
  PrivacyPolicyNav,
  PrivacyPolicyContent,
} from "components/Legals/PrivacyPolicy"

const PrivacyPolicy = () => {
  const title = "Polityka prywatności"
  const [tab, setTab] = useState(1)

  const policyData = {
    tab,
    setTab,
  }

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="privacy-policy">
        <div className="container-fluid">
          <PageHeader title={title} />
          <PageSubHeader title="Wstęp" />
          <ol>
            <li>
              Niniejsza Polityka Prywatności określa zasady przetwarzania danych
              osobowych pozyskanych za pośrednictwem sklepu internetowego
              www.memtech.pl (zwanego dalej: „Sklepem Internetowym”).
            </li>
            <li>
              Właścicielem Sklepu i jednocześnie Administratorem Danych
              Osobowych jest MEMTECH Sp. z o.o. Sp. k. z siedzibą w ul.
              Beskidzka 2a 34-322 Rychwałd, wpisana do rejestru przedsiębiorców
              Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla
              Łodzi-Śródmieścia w Łodzi, XX Wydział Gospodarczy Krajowego
              Rejestru Sądowego pod numerem KRS 0000530828, NIP: 7262655349,
              Regon: 360051116, zwana dalej MEMTECH Sp. z o.o. Sp. k.
            </li>
            <li>
              Dane osobowe zbierane przez MEMTECH Sp. z o.o. Sp. k. za
              pośrednictwem Sklepu Internetowego są przetwarzane zgodnie z
              Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
              dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
              związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
              rozporządzenie o ochronie danych), zwane również RODO.
            </li>
            <li>
              MEMTECH Sp. z o.o. Sp. k. dokłada szczególnej staranności do
              poszanowania prywatności Klientów odwiedzających Sklep
              Internetowy.
            </li>
          </ol>

          <div className="row">
            <div className="col-md-4">
              <PrivacyPolicyNav {...policyData} />
            </div>
            <div className="col-md-8">
              <PrivacyPolicyContent {...policyData} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy
